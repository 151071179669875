import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Link from './link';
import Col from 'react-bootstrap/Col';
import { getImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';
import { FaArrowRight } from 'react-icons/fa';
import Navigation from './navigation';
import Button from 'react-bootstrap/Button';
import { GatsbyImage } from 'gatsby-plugin-image';

const ServiceHero = ({
  title,
  subTitle,
  description,
  image,
  imageAlt,
  ctaText,
  eHeight,
  btnText = 'Contact',
}) => {
  const emptyheight = eHeight || '177px';
  return (
    <Container
      style={{ minHeight: '60vh' }}
      className='position-relative d-none  d-lg-block py-0 my-0 px-0 mx-0'
      fluid
    >
      <div className='position-absolute top-0 w-100'>
        <Navigation />
      </div>

      <Row className='mx-0 px-0  h-100'>
        <Col
          style={{ marginTop: '11vh' }}
          className='pt-8 d-flex ps-xl-8 ps-lg-4 '
          lg={4}
        >
          <div className='d-block'>
            <h1 className='text-primary display-4 fw-bold'>{title}</h1>
            <hr className='bg-secondary  hr-big w-80 ' />
          </div>
        </Col>
        <Col className='px-0 mx-0' lg={8}>
          <div
            style={{
              paddingTop: '11vh',
              borderTopLeftRadius: '8vh',
              borderBottomRightRadius: '8vh',
            }}
            className='bg-primary     text-white '
          >
            <Row className='pt-6 justify-content-xl-end px-0 mx-0 justify-content-center mw-100 vh-90 mb-xxl-5 vh-xxl-80 '>
              <Col xl={6} lg={10}>
                <h2>{subTitle}</h2>
                <p>{description}</p>
                <div
                  style={{ height: emptyheight }}
                  className='position-relative'
                >
                  <div
                    style={{
                      background: 'rgba(4, 114, 153, 0.45)',
                      backdropFilter: 'blur(48px)',
                      width: '520px',
                    }}
                    className={`pt-3 pb-4 px-5 text-center  position-absolute top-40 start--30`}
                  >
                    <p className='text-white  fs-4'>{ctaText}</p>
                    <Button
                      variant='outline-white'
                      as={Link}
                      to='/contact-us'
                      className='w-100 w-md-auto  pb-2 cta-btn '
                    >
                      {btnText}
                    </Button>
                  </div>
                </div>
              </Col>
              <Col
                className='text-center pb-xxl-5 pe-5 d-lg-none d-xl-block'
                lg={5}
              >
                <GatsbyImage
                  className='left-rounded'
                  image={image}
                  alt={imageAlt}
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ServiceHero;
