import { Link, useStaticQuery, graphql } from 'gatsby';
import * as React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Layout from '../../components/layout';
import Button from 'react-bootstrap/Button';
import { getImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import { Helmet } from 'react-helmet';
import ImageSection from '../../components/image-section';
import ServiceHero from '../../components/service-hero';
import ServiceHeroMobile from '../../components/service-hero-mobile';
import SVGPP1 from '../../components/svg/pp1-svg';
import SVGPP2 from '../../components/svg/pp2-svg';
import SVGPP3 from '../../components/svg/pp3-svg';
import HTMLReactParser from 'html-react-parser';

const PartitionsPage = () => {
  const data = useStaticQuery(graphql`
    query {
      heroImg: wpMediaItem(title: { eq: "Partition-Hero-PP" }) {
        altText
        localFile {
          publicURL
          childImageSharp {
            original {
              height
              width
            }
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
      NSWTImg: wpMediaItem(title: { eq: "Not-Sure_which-Type-Image-PP" }) {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
      GPImg: wpMediaItem(title: { eq: "Glazed-Partition-Image-PP" }) {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
      WFCImg: wpMediaItem(title: { eq: "Walls-Floors-Ceilings-Image-PP" }) {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
      schemaLogo: wpMediaItem(title: { eq: "Colston-Logo" }) {
        altText
        localFile {
          publicURL
          childImageSharp {
            original {
              height
              width
            }
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: TRACED_SVG
            )
          }
        }
      }
      site {
        siteMetadata {
          siteUrl
        }
      }
      OP: wpOfficePartition {
        officePartitionsFields {
          heroSection {
            btnText
            heading1
            heading1Body
            image {
              node {
                altText
                localFile {
                  publicURL
                  childImageSharp {
                    original {
                      height
                      width
                    }
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
            subheading
            title
          }
          partitionAssessmentSection {
            body
            btnText
            subheading
            title
            image {
              node {
                altText
                localFile {
                  publicURL
                  childImageSharp {
                    original {
                      height
                      width
                    }
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
          partitionsRequirementsSection {
            body
            btnText
            title
            subheading
            image {
              node {
                altText
                localFile {
                  publicURL
                  childImageSharp {
                    original {
                      height
                      width
                    }
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
          glazedPartitionSection {
            body
            heading1
            heading1Body
            title
            image {
              node {
                altText
                localFile {
                  publicURL
                  childImageSharp {
                    original {
                      height
                      width
                    }
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const siteUrl = data.site.siteMetadata.siteUrl;
  const heroImg = data.heroImg?.localFile.childImageSharp.gatsbyImageData;
  const NSWTImg = data.NSWTImg?.localFile.childImageSharp.gatsbyImageData;
  const GPImg = data.GPImg?.localFile.childImageSharp.gatsbyImageData;
  const WFCImg = data.WFCImg?.localFile.childImageSharp.gatsbyImageData;
  const OP = data.OP?.officePartitionsFields;
  console.log(data);
  const serviceSchema = {
    '@context': 'https://schema.org/',
    '@type': 'Product',
    '@id': 'https://www.colston.ltd.uk/services/partitions/#partitions',
    image: `https://www.colston.ltd.uk${data.heroImg.localFile.publicURL}`,
    mpn: 'https://www.colston.ltd.uk/services/partitions',
    sku: 'https://www.colston.ltd.uk/services/partitions',
    description:
      'At Colston, we can help you configure your office space by providing a range of partitions, whether they are permanent or demountable partitions and walls.​',
    logo: `https://www.colston.ltd.uk${data.schemaLogo.localFile.publicURL}`,
    name: 'Colston Ltd',
    url: 'https://www.colston.ltd.uk',
    brand: {
      '@type': 'Organization',
      logo: `https://www.colston.ltd.uk${data.schemaLogo.localFile.publicURL}`,
      image: `https://www.colston.ltd.uk${data.heroImg.localFile.publicURL}`,
      name: 'Colston Ltd',
      url: 'https://www.colston.ltd.uk',
    },
    aggregateRating: {
      '@type': 'AggregateRating',
      reviewCount: '57',
      ratingValue: '5',
      bestRating: '5',
      worstRating: '1',
    },
    // review: {
    // 	"@type": "Review",
    // 	url: "https://www.dentalhouseexeter.co.uk/reviews",
    // 	datePublished: "2022-03-21T17:58:29+00:00",
    // 	reviewBody:
    // 		"This is the best dental practice I have known. Not only in terms of expertise but the lovely sociability of every single member of the practice team.",
    // 	author: {
    // 		"@type": "Person",
    // 		name: "Caroline P",
    // 	},
    // 	reviewRating: {
    // 		"@type": "Rating",
    // 		ratingValue: "5",
    // 		bestRating: "5",
    // 		worstRating: "1",
    // 	},
    // 	publisher: {
    // 		"@type": "Organization",
    // 		name: "Dental House Exeter",
    // 		sameAs: "https://www.dentalhouseexeter.co.uk",
    // 	},
    // },
  };

  const breadcrumb = {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: '1',
        name: 'Home',
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        '@type': 'ListItem',
        position: '2',
        name: 'Services',
        item: {
          url: `${siteUrl}/services`,
          id: `${siteUrl}/services`,
        },
      },

      {
        '@type': 'ListItem',
        position: '3',
        name: 'Partitions',
        item: {
          url: `${siteUrl}/services/partitions`,
          id: `${siteUrl}/services/partitions`,
        },
      },
    ],
  };

  return (
    <Layout>
      <div className='position-relative'>
        <Helmet>
          {' '}
          <script type='application/ld+json'>
            {JSON.stringify(breadcrumb)}
          </script>
        </Helmet>
        <Helmet>
          <script type='application/ld+json'>
            {JSON.stringify(serviceSchema)}
          </script>
        </Helmet>

        <GatsbySeo
          title='Office and Room Partitions  | Colston'
          description='At Colston, we can help you configure your office space by providing a range of partitions, whether they are permanent or demountable partitions and walls.'
          language='en'
          openGraph={{
            type: 'website',
            url: `${siteUrl}/services/partitions`,
            title: 'Partitions | Colston Ltd',
            description:
              'At Colston, we can help you configure your office space by providing a range of partitions, whether they are permanent or demountable partitions and walls.',
            images: [
              {
                url: `${data.heroImg?.localFile.publicURL}`,
                width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
                height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
                alt: `${data.heroImg?.altText}`,
              },
            ],
          }}
        />
        <SVGPP1
          style={{ zIndex: '-1' }}
          className='position-absolute d-none d-lg-block top-10 start-0'
        />
        <SVGPP2
          style={{ zIndex: '-1' }}
          className='position-absolute d-none d-lg-block bottom-0 start-0'
        />
        <SVGPP3
          style={{ zIndex: '-1' }}
          className='position-absolute d-none d-lg-block top-30 end-0'
        />
        <ServiceHero
          title={OP.heroSection.title}
          subTitle={OP.heroSection.heading1}
          description={HTMLReactParser(OP.heroSection.heading1Body)}
          image={
            OP.heroSection?.image.node?.localFile?.childImageSharp
              ?.gatsbyImageData
          }
          imageAlt={OP.heroSection?.image?.node?.altText}
          ctaText={OP.heroSection.subheading}
          btnText={OP.heroSection.btnText}
        />
        <ServiceHeroMobile
          title={OP.heroSection.title}
          subTitle={OP.heroSection.heading1}
          description={HTMLReactParser(OP.heroSection.heading1Body)}
          ctaText={OP.heroSection.subheading}
          btnText={OP.heroSection.btnText}
        />
        <section className='py-xl-10 pt-9 pb-5'>
          <ImageSection
            title={OP.glazedPartitionSection.title}
            image={
              OP.glazedPartitionSection?.image.node?.localFile?.childImageSharp
                ?.gatsbyImageData
            }
            imageAlt={OP.glazedPartitionSection?.image?.node?.altText}
            text={
              <span>
                {HTMLReactParser(OP.glazedPartitionSection.body)}
                <h3>{OP.glazedPartitionSection.heading1}</h3>
                {HTMLReactParser(OP.glazedPartitionSection.heading1Body)}
              </span>
            }
            imgSpan={6}
            textSpan={6}
            textPos='top-50 translate-middle-y start--20'
            rounded='top-left-rounded'
            imgWidth='100%'
            imgHeight='35rem'
          />
        </section>
        <section className='py-xl-10 pb-5'>
          <ImageSection
            title={OP.partitionAssessmentSection.title}
            image={
              OP.partitionAssessmentSection?.image.node?.localFile
                ?.childImageSharp?.gatsbyImageData
            }
            imageAlt={OP.partitionAssessmentSection?.image?.node?.altText}
            text={HTMLReactParser(OP.partitionAssessmentSection.body)}
            ctaText={OP.partitionAssessmentSection?.subheading}
            btnText={OP.partitionAssessmentSection?.btnText}
            order='last'
            imgSpan={6}
            textSpan={6}
            textPos='end--20 top--20'
            ctaPos='start--10'
            eHeight='150px'
            imgWidth='100%'
            rounded='right-rounded'
            cta={true}
          />
        </section>
        <section className='pb-5 pb-xl-0 pt-xl-8'>
          <ImageSection
            title={OP.partitionsRequirementsSection.title}
            image={
              OP.partitionsRequirementsSection?.image.node?.localFile
                ?.childImageSharp?.gatsbyImageData
            }
            imageAlt={OP.partitionsRequirementsSection?.image?.node?.altText}
            text={HTMLReactParser(OP.partitionsRequirementsSection.body)}
            ctaText={OP.partitionsRequirementsSection?.subheading}
            btnText={OP.partitionsRequirementsSection?.btnText}
            imgSpan={10}
            textSpan={8}
            textPos=' top-80 start-50 translate-middle-x custom-width'
            rounded='left-rounded'
            imgWidth='100%'
            eHeight='50px'
            ctaPos='top-100 start-50 translate-middle'
            cta={true}
            align='justify-content-center position-relative'
          />
          <div className='d-none d-xl-block' style={{ height: '40rem' }}></div>
        </section>
      </div>
    </Layout>
  );
};

export default PartitionsPage;
