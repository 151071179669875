import * as React from "react";

const SVGPP3 = (props) => (
	<svg
		width={720}
		height={1264}
		viewBox="0 0 720 1264"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<g opacity={0.1}>
			<circle
				cx={632}
				cy={632}
				r={436.909}
				stroke="#999A9C"
				strokeWidth={90.3501}
			/>
			<circle
				cx={632}
				cy={632}
				r={586.825}
				stroke="#00A8E4"
				strokeWidth={90.3501}
			/>
		</g>
	</svg>
);

export default SVGPP3;
