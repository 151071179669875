import * as React from "react";

const SVGPP1 = (props) => (
	<svg
		width={152}
		height={657}
		viewBox="0 0 152 657"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<g opacity={0.1}>
			<circle
				cx={-176.5}
				cy={328.5}
				r={230.577}
				stroke="#999A9C"
				strokeWidth={40}
			/>
			<circle
				cx={-176.5}
				cy={328.5}
				r={308.5}
				stroke="#00A8E4"
				strokeOpacity={0.8}
				strokeWidth={40}
			/>
		</g>
	</svg>
);

export default SVGPP1;
